import Header from "../../Components/Header"
import Footer from "../../Components/Footer"
import { useHistory } from "react-router-dom"
import React from "react"
import { setLocalItems } from "../../Helpers/Helpers"
import { AppContext } from "../../Context/AppContext"

import { isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth"
import { auth } from "../../Firebase/Config"
import { CONSTANTS } from "../../Helpers/Constants"
import { getDocsWithId } from "../../Services/FetchData"
import Loader from "../../Components/Loader/Loader"

export default function Continue() {
    const history = useHistory()
    const { setToken, token } = React.useContext(AppContext)
    const [isLoading, setIsLoading] = React.useState(false)
    React.useEffect(() => {
        async function fetchUser() {
            setIsLoading(true)
            if (isSignInWithEmailLink(auth, window.location.href)) {
                let email = window.localStorage.getItem('emailForSignIn');
                if (!email && token) {
                    //this will handle both cases if token is or not available because on login route we have added check that token shouldn't be there. If token found, it will redirect to dashboard else on login screen.
                    history.push("/login")
                } else {
                    try {
                        // The client SDK will parse the code from the link for you.
                        const user = await signInWithEmailLink(auth, email, window.location.href)
                        const userDetail = await getDocsWithId(CONSTANTS.COLLECTION_NAMES.USERS, user.user.uid)
                        setToken({ user: user.user.uid, userRole: userDetail.role })
                        setLocalItems(CONSTANTS.LOCAL_STORAGE_KEYS.USER, { user: user.user.uid, userRole: userDetail.role })
                        window.localStorage.removeItem('emailForSignIn');
                    } catch(error){
                        history.push("/login")
                    }
                }
            }
            history.push("/home")
            setIsLoading(false)
        }
        fetchUser()
    }, [])

    return (
        <>
            {isLoading && <Loader />}
            <Header />
            <div className="main-container dashboard-content check-mail-container">
                <div className="continue-to-dashboard">
                    {/* we have added this code to check redirection link*/}
                </div>
            </div>
            <Footer />
        </>
    )
}