export default function Input({
    label,
    type,
    onChange,
    name,
    value,
    error,
    placeholder,
    extraClasses
}) {
    return (
        <div className={`input-group ${extraClasses ? extraClasses : ""}`}>
            <label>
                {label}
            </label>
            <input
                type={type}
                onChange={onChange}
                name={name}
                value={value}
                placeholder={placeholder}
            />
            <span className="error-message">{error}</span>
        </div>
    )
}