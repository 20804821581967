import tempPic from "../../../Assets/Images/temp-card-pic.png"
import sampleAudio from "../../../Assets/mp3.mp3"
import tempProfile from "../../../Assets/Images/temp-profile.png"
import PlyrComponent from "../../../Components/Player/Player"
import DownloadIcon from "../../../Assets/Images/download.svg"
import React from "react"
import { async } from "@firebase/util"
import { getBlob, getDownloadURL, ref } from "firebase/storage"
import { storage } from "../../../Firebase/Config"
export default function ProductsView({
    authorName,
    authorProfileImage,
    productImage,
    fileLink,
    productName,
    mediaType
}) {
    console.log(mediaType)
    const fileRef = ref(storage, fileLink)
    // React.useEffect(()=>{
    //     async function getUrl (){
    //             getDownloadURL(ref(storage, fileRef._location.path_))
    //             .then((url) => {
    //                 // `url` is the download URL for 'images/stars.jpg'

    //                 // This can be downloaded directly:
    //                 const xhr = new XMLHttpRequest();
    //                 xhr.responseType = 'blob';
    //                 xhr.onload = (event) => {
    //                   const blob = xhr.response;
    //                 };
    //                 xhr.open('GET', url);
    //                 xhr.send();

    //                 // Or inserted into an <img> element
    //                 // const img = document.getElementById('myimg');
    //                 console.log('src', url);
    //               })
    //               .catch((error) => {
    //                 // Handle any errors
    //               });
    //             // Or inserted into an <img> element
    //             // const img = document.getElementById('myimg');
    //             // img.setAttribute('src', url);
    //             // const res = await getBlob(fileRef)

    //     }
    //     getUrl()
    // },[])

    // console.log(blob, ".....")
    return (
        <div className="product-card">
            <div className="profile-section">
                <div className="profile-and-image">
                    <div className="author-profile-image">
                        <img
                            src={authorProfileImage}
                        />
                    </div>
                    <div className="author-name">
                        <p>{authorName}</p>
                    </div>
                </div>
                <div className="product-name">
                    <p>{productName}</p>
                </div>
            </div>
            <div className="image-section">
                <img
                    src={productImage}
                />
                <a title="press right click and click on save link as to download" download="audio" href={fileLink} target="_blank"><img className="download-icon" src={DownloadIcon} /></a>
            </div>
            <div className="player-section">
                <div className="player-controls">
                    {mediaType === "audio" ?
                        <PlyrComponent
                            sources={{
                                type: "audio",
                                sources: [
                                    {
                                        src: fileLink,
                                        type: "audio/mp3"
                                    }
                                ],
                            }}
                        />
                        :
                        <div className="player-controls">
                            <a className="btn login-btn link-btn" href={fileLink}>
                                Download
                            </a>
                        </div>
                    }
                </div>
            </div>

            <div className="top-left-border"></div>
            <div className="top-right-border"></div>
            <div className="bottom-left-border"></div>
            <div className="bottom-right-border"></div>
        </div>
    )
}