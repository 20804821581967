import { getAuth } from "firebase/auth";
import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from "firebase/storage"

import { FIREBASE_APP_ID, FIREBASE_DOMAIN, FIREBASE_KEY, FIREBASE_MEASUREMENT_ID, FIREBASE_PROJECT_ID, FIREBASE_SENDER_ID, FIREBASE_STORAGE_BUCKET } from "../config";

const firebaseConfig = {
  apiKey: FIREBASE_KEY,
  authDomain: FIREBASE_DOMAIN,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_SENDER_ID,
  appId: FIREBASE_APP_ID,
  measurementId: FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const storage = getStorage(app);


export { db, auth, storage }
