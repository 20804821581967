import { db } from "../Firebase/Config";
import { collection, getDocs, getDoc, doc, query, where } from "firebase/firestore";
import { CONSTANTS } from "../Helpers/Constants";



export const getDocsWithId = async (collectionName, docId) => {
    const docRef = doc(db, collectionName, docId)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
        return docSnap.data()
    } else {
        return CONSTANTS.ERROR_MESSAGES.NO_DOCUMENTS_FOUND
    }


    return docSnap
}

export const getCompleteCollection = async (collectionName) => {
    const tempData = []
    const querySnapshot = await getDocs(collection(db, collectionName));
    querySnapshot.forEach((doc) => {
        tempData.push({ ...doc.data(), doc_id: doc.id })
    });
    return tempData
}

export const queryToGetDoc = async (
    collectionName,
    queryObject
) => {
    const tempData = []
    const collectionRef = collection(db, collectionName)
    const q = query(
        collectionRef,
        where(queryObject.field, queryObject.comparator, queryObject.value)
    )
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        tempData.push({ doc_id: doc.id, ...doc.data() })
    });
    return tempData
}