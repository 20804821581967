import Header from "../../Components/Header"
import Footer from "../../Components/Footer"

export default function CheckMail() {
    return (
        <>
            <Header />
            <div className="main-container dashboard-content check-mail-container">
                <div className="check-mail">
                    We have emailed you a magic link to sign in
                </div>
            </div>
            <Footer/>
        </>
    )
}