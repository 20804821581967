import Header from "../../../Components/Header";
import Footer from "../../../Components/Footer"
import ProductsView from "./ProductsView";
import DashboardContent from "./DashboardContent";
import Loader from "../../../Components/Loader/Loader";

export default function DashboardView({ propsList }) {
    const { logOutHandler, isLoading, walletHandler } = propsList
    return (
        <>
            {isLoading && <Loader/>}
            <Header
                logOutHandler={logOutHandler}
                walletHandler={walletHandler}
            />
            <div className="main-container dashboard-content">
                <DashboardContent
                    propsList={propsList}
                />
            </div>
            <Footer />
        </>
    )
}