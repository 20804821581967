import AppProvider from "./Context/AppContext";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { CustomerAuthorProtected, LandingPage } from "./Routers";
import LoginScreen from "./Screens/Login";
import "./Assets/style.css"
import Dasboard from "./Screens/Dashboard";
import MyPlayer from "./Components/Player/Player";
import CheckMail from "./Screens/CheckMail/CheckMail";
import Continue from "./Screens/Continue/Continue";
function App() {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <AppProvider>
            <LandingPage exact path="/login" component={LoginScreen}/>
            <LandingPage exact path ="/check-mail" component={CheckMail}/>
            <Route exact path ="/continue" component={Continue}/>
            <CustomerAuthorProtected exact path="/" component={Dasboard} />
            <CustomerAuthorProtected exact path="/home" component={Dasboard} />
          </AppProvider>
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
