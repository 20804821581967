import { CONSTANTS } from "../../../Helpers/Constants"
import ProductsView from "./ProductsView"

export default function DashboardContent({ propsList }) {
    const { allUserProducts, isLoading } = propsList
    return (
        <>
            <div className="dashboard-header">
                <h1>{CONSTANTS.GENERAL.DASHBOARD_WELCOME_NOTE}</h1>
            </div>
            <div className="assets-border-container">
                <hr />
                <div className="assets-border">
                    <div>
                        {CONSTANTS.GENERAL.YOUR_ASSETS_TXT}
                    </div>
                    {/* <div>
                        <a className="btn login-btn link-btn" href="https://firebasestorage.googleapis.com/v0/b/supernft-society-production.appspot.com/o/zipProducts%2FPALUU%20LA%CC%88HTEELLE%20-%20Jumalan%20Lahja%20Live-20221007T060400Z-001.zip?alt=media&token=4d42684d-243e-46a0-9853-dfb232c7a9ce">
                            Download
                        </a>
                    </div> */}
                </div>
                <hr />
            </div>
            <div className="product-cards-section">
                {allUserProducts?.map((products, index) => {
                    return (
                        <ProductsView
                            key={index + products.name}
                            authorName={products.authorName}
                            authorProfileImage={products.authorProfileImage}
                            productImage={products.productImage}
                            fileLink={products.link}
                            productName={products.name}
                            mediaType={products.mediaType}
                        />
                    )
                })
                }
            </div>
        </>
    )
}