import { getDownloadURL, ref } from "firebase/storage"
import { storage } from "../Firebase/Config"

export const authErrorSanitizer = (message) => {
    return message.split("/")[1].split("-").join(" ").split(")")[0]
}

export const setLocalItems = (key, data) => {
    localStorage.setItem(key, JSON.stringify(data))
}

export const getLocalItems = (key) => {
    let data = JSON.parse(localStorage.getItem(key))
    return data
}

export const removeLocalItems = (key) => {
    localStorage.removeItem(key)
}


export const setFileUrl = async (filePath) => {
    const url = await getDownloadURL(ref(storage, filePath))
    return url
}