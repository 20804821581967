import React from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../Context/AppContext";
import { logOutUser } from "../../Firebase/AuthFunctions";
import { CONSTANTS } from "../../Helpers/Constants";
import { removeLocalItems } from "../../Helpers/Helpers";
import { getDocsWithId } from "../../Services/FetchData";
import DashboardView from "./Views/DashboardView";
import defaultAvator from "../../Assets/Images/img_avatar.png";
import Swal from "sweetalert2";
import { updateData } from "../../Services/UpdateData";

export default function Dasboard() {
    const { token, setToken, setCurrentUser, currentUser } = React.useContext(AppContext)
    const [allUserProducts, setAllUserProducts] = React.useState([])
    const history = useHistory()
    const [isLoading, setIsLoading] = React.useState(false)
    const [walletKey, setWalletKey] = React.useState("")

    const walletHandler = (docId) => {
        const id = docId || currentUser?.profileId
        Swal.fire({
            title: CONSTANTS.GENERAL.ENTER_WALLET_KEY,
            text: CONSTANTS.GENERAL.DO_IT_LATER_TXT,
            input: 'textarea',
            inputValue: walletKey,
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: CONSTANTS.GENERAL.SUBMIT_TXT,
            showLoaderOnConfirm: true,
            preConfirm: async (key) => {
                try {
                    if (key === "") {
                        return Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: CONSTANTS.ERROR_MESSAGES.WALLET_ADDRESS_CANNOT_BE_EMPTY,
                          })
                    }
                    setWalletKey(key)
                    await updateData(
                        CONSTANTS.COLLECTION_NAMES.CUSTOMER_PROFILES,
                        id,
                        {walletKey: key}
                    )
                    return Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: CONSTANTS.GENERAL.WALLET_KEY_SAVED,
                        showConfirmButton: false,
                        timer: 1500
                      })
                } catch(e){
                    return Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: e.message,
                      })
                }
            },
            allowOutsideClick: () => !Swal.isLoading()
        })
    }

    React.useEffect(() => {
        async function getProfile() {

            setIsLoading(true)
            const res = await getDocsWithId(CONSTANTS.COLLECTION_NAMES.USERS, token.user)
            if (res[CONSTANTS.DOC_FIELDS.USERS.ROLE] === CONSTANTS.AUTH_CONSTANTS.AUTHOR_ROLE) {
                const userResponse = await getDocsWithId(CONSTANTS.COLLECTION_NAMES.AUTHOR_PROFILES, res[CONSTANTS.DOC_FIELDS.USERS.PROFILE_ID].id)
                const tempData = {
                    ...userResponse,
                    role: res[CONSTANTS.DOC_FIELDS.USERS.ROLE],
                    profileId: res[CONSTANTS.DOC_FIELDS.USERS.PROFILE_ID].id
                }
                getProducts(userResponse[CONSTANTS.DOC_FIELDS.AUTHOR_PROFILES.PRODUCTS])
                setCurrentUser(tempData)

            } else if (res[CONSTANTS.DOC_FIELDS.USERS.ROLE] === CONSTANTS.AUTH_CONSTANTS.CUSTOMER_ROLE) {
                const userResponse = await getDocsWithId(CONSTANTS.COLLECTION_NAMES.CUSTOMER_PROFILES, res[CONSTANTS.DOC_FIELDS.USERS.PROFILE_ID].id)
                const tempData = {
                    ...userResponse,
                    role: res[CONSTANTS.DOC_FIELDS.USERS.ROLE],
                    profileId: res[CONSTANTS.DOC_FIELDS.USERS.PROFILE_ID].id
                }
                setCurrentUser(tempData)
                userResponse.walletKey && setWalletKey(userResponse.walletKey)
                getProducts(userResponse[CONSTANTS.DOC_FIELDS.CUSTOMER_PROFILES.PURCHASED_PRODUCTS])
            } else {
                const userResponse = await getDocsWithId(
                    CONSTANTS.COLLECTION_NAMES.USERS, res[CONSTANTS.DOC_FIELDS.USERS.PROFILE_ID].id
                )
                const tempData = {
                    ...userResponse,
                    role: res[CONSTANTS.DOC_FIELDS.USERS.ROLE],
                    profileImage: defaultAvator
                }
                setCurrentUser(tempData)
            }
            setIsLoading(false)
        }
        getProfile()
    }, [])

    const getProducts = async (prodArray) => {
        let products = []
        for (let prodId of prodArray) {
            const res = await getDocsWithId(CONSTANTS.COLLECTION_NAMES.PRODUCT_DETAILS, prodId)
            products.push(res)
        }
        setAllUserProducts(products)
    }

    const logOutHandler = () => {
        logOutUser()
        removeLocalItems(CONSTANTS.LOCAL_STORAGE_KEYS.USER)
        setCurrentUser("")
        setToken(null)
        setAllUserProducts([])
        history.push("/login")
    }

    const propsList = {
        logOutHandler,
        allUserProducts,
        isLoading,
        walletHandler
    }

    return (
        <>
            <DashboardView
                propsList={propsList}
            />
        </>
    )
}