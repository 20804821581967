import React from "react";
import { AppContext } from "../../Context/AppContext";
import { CONSTANTS } from "../../Helpers/Constants";
import placeHolderImage from "../../Assets/Images/place-holder-avatar.jpg"
import SuperNFTLogo from "../../Assets/Images/SNFTS_logo.png"
import Swal from "sweetalert2";

export default function Header({ logOutHandler, walletHandler }) {
    const { currentUser } = React.useContext(AppContext)
    const [openLogout, setOpenLogout] = React.useState(false)


    return (
        <div className="header">
            <div className="logo">
                <a href="https://supernftsociety.com/">
                    <img src={SuperNFTLogo} alt="logo" />
                </a>
            </div>
            {
                currentUser &&
                <div className="wallet-profile-section">
                    <div className="wallet-button" onClick={() => walletHandler("")}>Connect your wallet</div>
                    <div className="header-profile-section">
                        <div className="profile-section" onClick={() => setOpenLogout(!openLogout)}>
                            <div className="user-name">
                                <p>{currentUser?.firstName}</p>
                            </div>
                            <div className="user-profile-image" >
                                <img src={currentUser?.profileImage || placeHolderImage} />
                            </div>
                        </div>
                        {openLogout && <div className="profile-dropdown">
                            <div className="logout-button" onClick={logOutHandler}>Log out</div>
                        </div>}
                    </div>
                </div>
            }
        </div>
    )
}