export const CONSTANTS = {
    AUTH_CONSTANTS: {
        EMAIL_ADDRESS: "Email",
        ENTER_EMAIL_ADDRESS: "Enter your email address",
        PASSWORD: "Password",
        LOGIN_TXT: "Login",
        RESET_PSW_TXT: "Reset password",
        LOG_OUT: "Log out",
        ADMIN_ROLE: "admin",
        CUSTOMER_ROLE: "customer",
        AUTHOR_ROLE: "author",
        SIGN_IN_TXT: "Sign in"
    },
    GENERAL: {
        ARTISTS_TXT: "Artists",
        TEAM_TXT: "Team",
        FAQ_TXT: "FAQs",
        REGISTER_TXT: "Register",
        DASHBOARD_WELCOME_NOTE: "Welcome to SUPER NFT SOCIETY",
        SUPER_NFT_TXT: "SUPER NFT SOCIETY",
        YOUR_ASSETS_TXT: "Your assets",
        SOCIAL_TXT: "Social",
        TWITTER_TXT: "Twitter",
        INSTA_TXT: "Instagram",
        POLICY_TXT: "Policy",
        PRIVACY_POLICY_TXT: "Privacy Policy",
        TNC_TXT: "Terms & Conditions",
        DESIGNED_BY: <>Maintained by <span className="two-doors-span"><a  href="https://diyainteractive.com/" target={"_blank"}>Diya Interactive</a></span></>,
        WALLET_KEY_SAVED: "Your ethereum address has been saved",
        ENTER_WALLET_KEY: "Enter your Ethereum public address.",
        DO_IT_LATER_TXT: "Paste your wallet address number, consisting of 42 characters starting with 0x or you can do it later by clicking on profile icon",
        SUBMIT_TXT: "Submit",
        CANCEL_TXT: "Cancel",
        LOGIN_SLOGAN: "Keep calm and collect your NFTs"
    },
    FORM_FIELDS_NAME: {
        EMAIL_ADDRESS: "emailAddress",
        PASSWORD: "password"
    },
    ERROR_MESSAGES: {
        EMPTY_EMAIL_ERROR: "Email field can not be empty",
        EMPTY_PASSWORD_ERROR: "Password field can not be empty",
        NO_DOCUMENTS_FOUND: "No such document!",
        WALLET_ADDRESS_CANNOT_BE_EMPTY: "Address field can not be empty"
    },
    COLLECTION_NAMES: {
        AUTHOR_PROFILES: "author_profiles",
        CUSTOMER_PROFILES: "customer_profiles",
        PRODUCT_DETAILS: "products_details",
        USERS: "users"
    },
    DOC_FIELDS: {
        AUTHOR_PROFILES: {
            CITY: "city",
            CONTACT_NUMBER: "contactNumber",
            COUNTRY: "country",
            CREATED_BY: "createdBy",
            CREATED_ON: "createdOn",
            EDUCATION: "education",
            EMAIL_ADDRESS: "emailAddress",
            FIRST_NAME: "firstName",
            LAST_NAME: "lastName",
            MODIFIED_BY:"modifiedBy",
            MODIFIED_ON: "modifiedOn",
            PRODUCTS: "products",
            PROFILE_IMAGE: "profileImage"
        },
        CUSTOMER_PROFILES: {
            CITY: "city",
            CONTACT_NUMBER: "contactNumber",
            COUNTRY: "country",
            CREATED_BY: "createdBy",
            CREATED_ON: "createdOn",
            EMAIL_ADDRESS: "emailAddress",
            FIRST_NAME: "firstName",
            GENDER: "gender",
            LAST_NAME: "lastName",
            MODIFIED_BY:"modifiedBy",
            MODIFIED_ON: "modifiedOn",
            PROFILE_IMAGE: "profileImage",
            PURCHASED_PRODUCTS: "purchasedProducts",
        },
        PRODUCT_DETAILS: {
            AUTHOR: "author",
            AUTHOR_NAME: "authorName",
            AUTHOR_PROFILE_IMAGE: "authorProfileImage",
            CATEGORY: "category",
            CREATED_BY: "createdBy",
            CREATED_ON: "createdOn",
            DOWNLOAD_COUNTS: "downloadCounts",
            LANGUAGE: "language",
            LINK: "link",
            MEDIA_TYPE: "mediaType",
            MODIFIED_BY: "modifiedBy",
            MODIFIED_ON: "modifiedOn",
            NAME: "name",
            PRICE: "price",
            TAGS: "tags",
            PRODUCT_IMAGE: "productImage"
        },
        USERS: {
            CREATED_BY: "createdBy",
            CREATED_ON: "createdOn",
            FIRST_NAME: "firstName",
            IS_ACTIVE: "isActive",
            LAST_NAME: "lastName",
            MODIFIED_BY: "modifiedBy",
            MODIFIED_ON: "modifiedOn",
            PROFILE_ID: "profileId",
            ROLE: "role",
            EMAIL_ADDRESS: "emailAddress"
        }
    },
    LOCAL_STORAGE_KEYS: {
        USER: "user",
    }
}