import Input from "../Inputs";
import { CONSTANTS } from "../../Helpers/Constants";

export default function Login({
    loginHandler,
    loginChangeHandler,
    email,
    password,
    errorMessages,
}) {
    return (
        <div className="form-section">
            <form className="login-form">
                <div className="form-header">
                    <h2>{CONSTANTS.GENERAL.LOGIN_SLOGAN}</h2>
                </div>
                <div className="form-body">
                    <Input
                        type={"email"}
                        onChange={(e) => loginChangeHandler(e)}
                        name={CONSTANTS.FORM_FIELDS_NAME.EMAIL_ADDRESS}
                        value={email}
                        error={errorMessages?.email_error}
                        placeholder={CONSTANTS.AUTH_CONSTANTS.ENTER_EMAIL_ADDRESS}
                        extraClasses={"login-input"}
                    />
                    <button
                        className="btn login-btn"
                        onClick={loginHandler}
                    >
                        {CONSTANTS.AUTH_CONSTANTS.SIGN_IN_TXT}
                    </button>

                    {/* <div className="form-footer">
                        <div className="reset-password">
                            <a href="#">{CONSTANTS.AUTH_CONSTANTS.RESET_PSW_TXT}</a>
                        </div>
                    </div> */}
                </div>
            </form>
        </div>
    )
}