import { Audio } from 'react-loader-spinner';

export default function Loader() {
    return (
        <div className='loader-container'>
            <Audio
                height="80"
                width="80"
                radius="9"
                color="gray"
                ariaLabel="loading"
                wrapperStyle
                wrapperClass
            />
        </div>
    )
}