import { Route, Redirect } from "react-router-dom"
import { AppContext } from "../Context/AppContext"
import { useContext } from "react"
import { CONSTANTS } from "../Helpers/Constants"


export const CustomerAuthorProtected = ({ component: Component, ...rest }) => {
  //created to prevent the access of app without login
  const { token } = useContext(AppContext)
  return (
    <Route {...rest}
      render={(props) =>
        token?.user
          &&
          (token?.userRole === CONSTANTS.AUTH_CONSTANTS.CUSTOMER_ROLE || token?.userRole === CONSTANTS.AUTH_CONSTANTS.AUTHOR_ROLE)
          ?
          (
            <Component {...props} />
          ) :
          token?.userRole && token?.userRole === CONSTANTS.AUTH_CONSTANTS.ADMIN_ROLE ? (
            <Redirect
              to={{
                pathname: "/admin-dashboard",
                state: {
                  forcedLogout: true,
                  message: "CONSTANTS.UNAUTHORIZED_MESSAGE",
                },
              }}
            />
          ) :
            (
              <Redirect
                to={{
                  pathname: "/login",
                  state: {
                    forcedLogout: true,
                    message: "CONSTANTS.UNAUTHORIZED_MESSAGE",
                  },
                }}
              />
            )
      }
    />
  )
}

export const LandingPage = ({ component: Component, ...rest }) => {
  //created to prevent the logged in user from redirecting forcefully to Login
  const { token } = useContext(AppContext)

  return (
    <Route {...rest} render={
      props => !token ? <Component {...rest}{...props} /> : <Redirect to='/home' />} />
  )
}