import Footer from "../../../Components/Footer"
import Header from "../../../Components/Header"
import Login from "../../../Components/Login"
import featureImage from "../../../Assets/Images/login-image.gif"
import Loader from "../../../Components/Loader/Loader"

export default function LoginView({ propsList }) {
    const { loginHandler, loginChangeHandler, email, password, errorMessages, isLoading, signInLinkHandler } = propsList
    return (
        <>
        {isLoading && <Loader/>}
            <Header />
            <div className="main-container login-container">
                <div className="login-left-section">
                    <Login
                        loginChangeHandler={loginChangeHandler}
                        loginHandler={signInLinkHandler}
                        // loginHandler={loginHandler}
                        email={email}
                        password={password}
                        errorMessages={errorMessages}
                    />
                    <div className="login-gradient"></div>
                </div>
                <div className="login-right-section">
                    <div className="feature-image">
                        <img src={featureImage}/>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}