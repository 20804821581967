import { createUserWithEmailAndPassword, signInWithEmailAndPassword,signOut, sendPasswordResetEmail, sendEmailVerification, sendSignInLinkToEmail } from "firebase/auth";
import { auth } from "./Config";

export const createAccount = (email, password) => createUserWithEmailAndPassword(auth, email, password)


export const loginAccount = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password)
}

export const logOutUser = () => {
    return signOut(auth)
}


export const signInLink = (email, actionCodeSettings)=>{
    return sendSignInLinkToEmail(auth, email, actionCodeSettings)
}

// export const resetPassword = (email, url) => {
//     return sendPasswordResetEmail(auth, email, {url})
// }

// export const sendCustomerEmailVerification = () => {
//     return sendEmailVerification(auth.currentUser)
// }