import React, { useState } from "react";
import { loginAccount, signInLink } from "../../Firebase/AuthFunctions";
import { CONSTANTS } from "../../Helpers/Constants";
import { authErrorSanitizer, setLocalItems } from "../../Helpers/Helpers";
import { AppContext } from "../../Context/AppContext";
import { useHistory } from "react-router-dom";
import LoginView from "./Views/LoginView";
import { queryToGetDoc } from "../../Services/FetchData";

export default function LoginScreen() {
    const history = useHistory()
    const { setToken } = React.useContext(AppContext)
    const [email, setEmail] = React.useState("")
    const [password, setPassword] = React.useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [errorMessages, setErrorMessages] = React.useState({
        email_error: "",
        password_error: "",
    })
    const signInLinkHandler = async (e) => {
        e.preventDefault()
        if (!email) {
            setErrorMessages(updatedState => {
                return { ...updatedState, email_error: CONSTANTS.ERROR_MESSAGES.EMPTY_EMAIL_ERROR }
            })
        } else {
            setIsLoading(true)
            const queryObject = {
                field: CONSTANTS.DOC_FIELDS.USERS.EMAIL_ADDRESS,
                comparator: "==",
                value: email
            }
            const queryRes = await queryToGetDoc(CONSTANTS.COLLECTION_NAMES.USERS, queryObject)
            if (queryRes.length < 1) {
                setErrorMessages(updatedState => {
                    return { ...updatedState, email_error: "invalid email address" }
                })
            } else {
                if (queryRes[0].role === CONSTANTS.AUTH_CONSTANTS.ADMIN_ROLE) {
                    setErrorMessages(updatedState => {
                        return { ...updatedState, email_error: "Invalid user role. Please sign in as admin" }
                    })
                } else {
                    try {
                        const redirectUrl = `${window.location.origin}/continue`
                        const actionCodeSettings = {
                            url: redirectUrl,
                            handleCodeInApp: true,
                        };
                        await signInLink(email, actionCodeSettings)
                        localStorage.setItem("emailForSignIn", email)
                        history.push("/check-mail")
                    } catch (e) {
                        const sanitizedError = authErrorSanitizer(e.message)
                        if (sanitizedError.includes("email") || sanitizedError.includes("user")) setErrorMessages(updatedState => {
                            return { ...updatedState, email_error: sanitizedError }
                        })
                        if (sanitizedError.includes("password")) setErrorMessages(updatedState => {
                            return { ...updatedState, password_error: sanitizedError }
                        })
                    }
                }
            }
            setIsLoading(false)
        }
    }

    const loginHandler = async (e) => {
        setIsLoading(true)
        e.preventDefault()
        if (email && password) {
            try {

                const user = await loginAccount(email, password)
                setLocalItems(CONSTANTS.LOCAL_STORAGE_KEYS.USER, user.user.uid)
                setToken(user.user.uid)
                history.push("/home")
            } catch (e) {
                const sanitizedError = authErrorSanitizer(e.message)
                if (sanitizedError.includes("email") || sanitizedError.includes("user")) setErrorMessages(updatedState => {
                    return { ...updatedState, email_error: sanitizedError }
                })
                if (sanitizedError.includes("password")) setErrorMessages(updatedState => {
                    return { ...updatedState, password_error: sanitizedError }
                })
            }
        } else {
            if (email === "") {
                setErrorMessages(updatedState => {
                    return { ...updatedState, email_error: CONSTANTS.ERROR_MESSAGES.EMPTY_EMAIL_ERROR }
                })
            }
            if (password === "") {
                setErrorMessages(updatedState => {
                    return { ...updatedState, password_error: CONSTANTS.ERROR_MESSAGES.EMPTY_PASSWORD_ERROR }
                })
            }
        }
        setIsLoading(false)
    }

    const loginChangeHandler = (event) => {
        const name = event.target.name
        const value = event.target.value
        if (name === CONSTANTS.FORM_FIELDS_NAME.EMAIL_ADDRESS) {
            setEmail(value)
            setErrorMessages({ ...errorMessages, email_error: "" })
        } else {
            setPassword(value)
            setErrorMessages({ ...errorMessages, password_error: "" })
        }
    }

    const propsList = {
        loginHandler,
        loginChangeHandler,
        email,
        password,
        errorMessages,
        isLoading,
        signInLinkHandler
    }
    return (
        <>
            <LoginView
                propsList={propsList}
            />
        </>
    )
}