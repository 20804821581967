import { CONSTANTS } from "../../Helpers/Constants"
import SuperNFTLogo from "../../Assets/Images/SNFTS_logo.png"

export default function Footer() {
    return (
        <div className="footer-container">
            <div className="logo">
                <a href="https://supernftsociety.com/">
                    <img src={SuperNFTLogo} alt="image"/>
                </a>
            </div>
            <div className="social-policy-section">
                <div className="social">
                    <h4>{CONSTANTS.GENERAL.SOCIAL_TXT}</h4>
                    <p><a href="https://twitter.com/supernftsociety">{CONSTANTS.GENERAL.TWITTER_TXT}</a></p>
                    <p><a href="https://www.instagram.com/supernftsociety/">{CONSTANTS.GENERAL.INSTA_TXT}</a></p>
                </div>

                <div className="policy">
                    <h4>{CONSTANTS.GENERAL.POLICY_TXT}</h4>
                    <p><a href="https://supernftsociety.com/privacy-policy/">{CONSTANTS.GENERAL.PRIVACY_POLICY_TXT}</a></p>
                    <p><a href="https://supernftsociety.com/terms-conditions/">{CONSTANTS.GENERAL.TNC_TXT}</a></p>
                    <p className="design-by">{CONSTANTS.GENERAL.DESIGNED_BY}</p>
                </div>
            </div>
        </div>
    )
}